import React ,{useContext}from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { axiosInstanceOAuth } from '../config/AuthService';
import { Button } from 'react-bootstrap';
import { showNotificationSuccess, showNotificationError, Loader, showErrorAlert } from '../shared/Utils';
import MyContext from '../shared/MyContext';

const MultipleImgDownloadBtn = ({ selectedImageIds,allImages }) => {
    const { setLoadingContext } = useContext(MyContext);

  const handleDownload = async () => {
    const zip = new JSZip();
    setLoadingContext(true);
    for (const [imageId, isSelected] of Object.entries(selectedImageIds)) {
        if (isSelected) {
            const moduleName = allImages.find(x=> x.id == imageId)?.module; 
            const imageUrl = `/download?documentId=${imageId}&module=${moduleName}`;
            try {
                const response = await axiosInstanceOAuth.get(imageUrl, { responseType: 'blob' });
                const blob = response.data; 
                zip.file(`image-${imageId}.jpg`, blob);
            } catch (error) {
                console.error("Error downloading image:", imageId, error);
            }
        }
    }

    // Check if any images are selected and generate ZIP
    if (Object.values(selectedImageIds).some(value => value)) {
        zip.generateAsync({ type: "blob" })
            .then((content) => {
                saveAs(content, "selected-images.zip");
            });
            showNotificationSuccess("Zip File downloaded");
            setLoadingContext(false);
    } else {
        showNotificationError("No images selected for download.");
        setLoadingContext(false);
    }
};



  return (
    <Button  className="btn btn-primary btn-icon text-white me-3" onClick={handleDownload}> <i className="fe fe-download"></i>  Download Selected</Button>
  );
};

export default MultipleImgDownloadBtn;
