import React,{useState} from 'react';

const MyContext = React.createContext();
function MyContextProvider ({children}) {
    // Context state
    const [loadingContext,setLoadingSpinner] = useState(false);


    const setLoadingContext =(val)=>{
      setLoadingSpinner(val);
    }
   

        return (
          <MyContext.Provider
            value={{
                setLoadingContext,
                loadingContext
            }}
          >
            {children}
          </MyContext.Provider>
        );
      

}


export default MyContext;

export { MyContextProvider };