import React, { Fragment, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import App from './layouts/App'
import Loader from './layouts/layoutcomponents/loader';
import './index.scss'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ImageSearchComponent from './gacware/ImageSearchComponent';
import { msalConfig } from './config/AzureAuthConfig';
import { MyContextProvider } from "./shared/MyContext";
const Custompages = lazy(() => import("./layouts/custompages"));

//Errorpages
const Errorpage400 = lazy(() => import("./components/custompages/errorpages/400/400"));
const Errorpage401 = lazy(() => import("./components/custompages/errorpages/401/401"));

import {
  PublicClientApplication,
  InteractionType,
  EventType,
  InteractionRequiredAuthError // Import InteractionRequiredAuthError for handling specific errors
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { UserContextProvider } from './shared/UserContext';



const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)

    // Acquire token silently
    msalInstance.acquireTokenSilent({
      scopes: ["User.Read"], // Add the scopes used in your application
      account: account
    }).then(response => {
      sessionStorage.setItem('authToken', response.accessToken); // Changed to sessionStorage
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle cases where user interaction is required
        msalInstance.acquireTokenRedirect({
          scopes: ["User.Read"]
        });
      } else {
        console.error(error);
      }
    });

  }
  else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ) {
    msalInstance.acquireTokenRedirect({
      scopes: ["User.Read"]
    });
  }
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <Fragment>

    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <MsalProvider instance={msalInstance}>
          <Routes>
            <Route
              path={`${import.meta.env.BASE_URL}/`}
              element={<UserContextProvider><App /></UserContextProvider>}
            >
              <Route index element={<MyContextProvider><ImageSearchComponent /></MyContextProvider>} path={`${import.meta.env.BASE_URL}/`} />
            </Route>
            <Route path={`${import.meta.env.BASE_URL}`} element={<Custompages />}>
              <Route path={`${import.meta.env.BASE_URL}badrequest`} element={<Errorpage400 />} />
              <Route path={`${import.meta.env.BASE_URL}unauthorized`} element={<Errorpage401 />} />
            </Route>

          </Routes>
        </MsalProvider>
      </React.Suspense>
    </BrowserRouter>

  </Fragment>

)