import axios from 'axios';
const DOCUMENT_API =import.meta.env.VITE_DOCUMENT_API;
const GACWARE_API = import.meta.env.VITE_GACWARE_API;
const principleCode = import.meta.env.VITE_PRINCIPLE_CODE;
const systemCode = import.meta.env.VITE_SYSTEM_CODE;
export const getAuthToken = () => {

    // Logic to retrieve your stored auth token (e.g., from local storage or a cookie)
    return sessionStorage.getItem('authToken');
  };
  

  
  export const axiosInstanceOAuth = axios.create({
    baseURL: DOCUMENT_API,
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key' : import.meta.env.VITE_SUBSCRIPTION_KEY
    },
  });
  
  axiosInstanceOAuth.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });


  export const axiosGacwareInstanceOAuth = axios.create({
    baseURL: GACWARE_API,
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key' : import.meta.env.VITE_GACWARE_SUBSCRIPTION_KEY,
      'applicationinstancecode':systemCode,
      "customerCode" : principleCode,
      'Ocp-Apim-Trace' : true
    },
  });
  
  axiosGacwareInstanceOAuth.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });