import React, { Fragment,useContext,useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Header from "./layoutcomponents/header";
import Sidebar from "./layoutcomponents/sidebar";
import Footer from "./layoutcomponents/footer";
import Switcher from "./layoutcomponents/Switcher";
import RightSidebar from "./layoutcomponents/rightsidebar";
import * as Switcherdata from "../commondata/Switcherdata";
import { Outlet } from "react-router-dom";
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../config/AzureAuthConfig';
import UserContext from "../shared/UserContext";
export default function App() {
  const { instance, accounts, inProgress } = useMsal();
  const{user,setUserContext} = useContext(UserContext);

 document.querySelector("body").classList.add("app", "sidebar-mini", "ltr", "light-mode");
 document.querySelector("body")?.classList.remove("login-img");
 
 useEffect(()=>{
  if(accounts.length > 0) {
  setUserContext(accounts[0]);
  }
},[accounts.length]);

  /*return (
    <Fragment>
      <div className="horizontalMenucontainer">
      <Switcher />
        <div className="page">
          <div className="page-main">
            <Header />
            <div className="main-content app-content ">
              <div className="side-app">
                <div className="main-container container-fluid"
                  onClick={() => {
                    Switcherdata.responsiveSidebarclose();
                    Switcherdata.Horizontalmenudefultclose();
                  }}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <RightSidebar />
          <Footer />
        </div>
      </div>
    </Fragment>
  );
  */

  return (
   <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <Fragment>
      <div className="horizontalMenucontainer">
      <Switcher />
        <div className="page">
          <div className="page-main">
            <Header />
            <div className="main-content" style={{padding:'10px'}}>
              <div className="side-app">
                <div className="main-container container-fluid"
                  onClick={() => {
                    Switcherdata.responsiveSidebarclose();
                    Switcherdata.Horizontalmenudefultclose();
                  }}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <RightSidebar />
          <Footer />
        </div>
      </div>
    </Fragment> 
    </MsalAuthenticationTemplate>
  );
}

