import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_GACWARE_API;
const username = import.meta.env.VITE_GACWARE_USER;
const password = import.meta.env.VITE_GACWARE_PWD;
const principleCode = import.meta.env.VITE_PRINCIPLE_CODE;
const systemCode = import.meta.env.VITE_SYSTEM_CODE;
export const httpGacwareInstanceAuth = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'applicationinstancecode':systemCode,
    "customerCode" : principleCode
  },
});
httpGacwareInstanceAuth.interceptors.request.use(config => {
  const token = btoa(`${username}:${password}`); 
  config.headers['Authorization'] = `Basic ${token}`;
  return config;
});
