import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import Swal from "sweetalert2";

const center_loader = {
  display: "block",
  margin: "0 auto",
  borderColor: "blue",
  position: "fixed",
  zIndex: "9999",
  left: "50%",
  top: "55%"
}
 const Loader = ({loading}) => {
  return (
      loading &&  <div style={center_loader}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </div>
  );
}

const  showNotificationError =(message)=> {
    toast.error(<p className="text-white tx-16 mb-0">{message}</p>, {
         position: toast.POSITION.TOP_CENTER,
         hideProgressBar: true,
         autoClose:5000,
         theme: "colored",
       }
     )
}

const  showWarningError =(message)=> {
  toast.warning(<p className="text-white tx-16 mb-0">{message}</p>, {
       position: toast.POSITION.TOP_CENTER,
       hideProgressBar: true,
       autoClose:5000,
       theme: "colored",
     }
   )
}
 const  showNotificationSuccess =(message) =>{
    toast.success(<p className="text-white tx-16 mb-0">{message}</p>, {
         position: toast.POSITION.TOP_CENTER,
         hideProgressBar: true,
         autoClose:5000,
         theme: "colored",
       }
     )
}

const  showErrorAlert =(messages) => {
  console.log(messages);
  let alertMessage ="";
  messages.map((message,index)=>{
    alertMessage += (index+1)+"."+message+"\n";
  });
  Swal.fire({
    icon: "error",
    text: alertMessage,
    allowOutsideClick: false,
    confirmButtonText: "ok",
  });
}

const  showWarningAlert =(messages) => {
  console.log(messages);
  let alertMessage ="";
  messages.map((message,index)=>{
    alertMessage += (index+1)+"."+message+"\n";
  });
  Swal.fire({
    icon: "warning",
    text: alertMessage,
    allowOutsideClick: false,
    confirmButtonText: "ok",
  });
}



export {showNotificationError, showNotificationSuccess,showErrorAlert,showWarningAlert,Loader,showWarningError};