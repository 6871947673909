import React, { useState, useEffect, useCallback, useContext } from 'react';
import DatePicker from "react-multi-date-picker";
import MyImageGallery from './MyImageGalleryComponent';
import 'react-image-gallery/styles/css/image-gallery.css';
import PageHeader from "../layouts/layoutcomponents/pageheader";
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { httpGacwareInstanceAuth } from '../config/HttpService';
import { axiosInstanceOAuth } from '../config/AuthService';
import UserContext from "../shared/UserContext";
import { useLocation, useNavigate } from 'react-router-dom';



import { ToastContainer, toast, Slide, Flip } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { showNotificationSuccess, showNotificationError, Loader, showErrorAlert, showWarningAlert, showWarningError } from '../shared/Utils';
import MyContext from '../shared/MyContext';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';


export default function ImageSearchComponent() {
  const location = useLocation();
  const navigate = useNavigate ();
  const { setLoadingContext, loadingContext } = useContext(MyContext);
  const [images, setImages] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [truckOptions, setTruckOptions] = useState([]);
  const [palletOptions, setPalletOptions] = useState([]);
  const [principleOptions, setPrincipleOptions] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [gallerResetKey, setGallerResetKey] = useState(0);
  const{user,setUserContext} = useContext(UserContext);


  const generateInitialFormData = () => ({
    moduleName: null,
    vehicleNo: null,
    palletNo: null,
    principleCode :null,
    dispatchDate: null,
    orderNumber: ''
  });
  const [formData, setFormData] = useState(generateInitialFormData());// useState(generateInitialFormData());

  useEffect(() => {
    setLoadingContext(true);
    let search = location.search;
    if (search.startsWith('?')) {
      search = search.slice(1);
    }
    search = atob(search);
    const queryParams = new URLSearchParams(search);

    const userId = queryParams.get('userId');
  
    if (!userId) {
      console.error('UserId is missing from the URL');
      showNotificationError("UserId missing from URL");
      setLoadingContext(false);
      return;
    }
   //let obj ={userId:"parvejalic1234",emailId:"parvejali.chodhary@gac.com"};
    let obj ={userId:userId,emailId:user.username};
    // Check if userId is authorized
    httpGacwareInstanceAuth.get(`/document/verify?userId=${obj.userId}&emailId=${obj.emailId}`)
      .then(response => {
        if (response.status === 200) {

          httpGacwareInstanceAuth.get(`/document/principles?userId=${obj.userId}`)
          .then((response) => {
            setPrincipleOptions(response.data.map(option => ({ value: option, label: option })));
          })
          .catch((error) => {
            console.error('Error fetching images:', error);
            showNotificationError('Error fetching principles:', error);
          })
          .finally(() => setLoadingContext(false));
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Redirect to unauthorized page if user is not authorized
          navigate('/unauthorized');
        } else {
          console.error('Error verifying user:', error);
          showNotificationError('Error verifying user', error);
        }
        setLoadingContext(false);
      });
  }, []);
  

  useEffect(() => {
    if (formData.principleCode) {
      setLoadingContext(true);
      Promise.all([
        httpGacwareInstanceAuth.get(`/document/modules?principleCode=${formData.principleCode}`),
        httpGacwareInstanceAuth.get(`/document/vehicles?principleCode=${formData.principleCode}`),
        httpGacwareInstanceAuth.get(`/document/pallets?principleCode=${formData.principleCode}`),
      ]).then((responses) => {
        setModuleOptions(responses[0].data.map(option => ({ value: option, label: option })));
        setTruckOptions(responses[1].data.map(option => ({ value: option, label: option })));
        setPalletOptions(responses[2].data.map(option => ({ value: option, label: option })));
      }).catch((error) => {
        console.error('Error fetching data based on principle code:', error);
        showNotificationError('Error fetching data based on principle code', error);
      }).finally(() => setLoadingContext(false));
    }
  }, [formData.principleCode]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (Array.from(queryParams).length > 0 && moduleOptions.length > 0 && truckOptions.length > 0 && palletOptions.length > 0) {
      let _formData = {};

      for (let [key, value] of queryParams.entries()) {
        if (key === "orderNumber" || key == "dispatchDate") {
          _formData[key] = value;
          continue;
        }

        let currentOptions = [];
        if (key === "moduleName") currentOptions = moduleOptions;
        if (key === "vehicleNo") currentOptions = truckOptions;
        if (key === "palletNo") currentOptions = palletOptions;

        const matchingOption = currentOptions.find(option => option.value.toLowerCase() === value.toLowerCase());
        if (matchingOption) {
          _formData[key] = matchingOption.value; // Use the case from the options to ensure consistency
        }
      }
      setFormData(prevFormData => ({
        ...prevFormData,
        ..._formData,
      }));

      //handleSearch({ formData: _formData }); // if auto fill of form is allowed
    }
  }, [location.search, moduleOptions, truckOptions, palletOptions]);

 //ENABLE WHEN query params passed from GACWARE in URL 
  /* useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (Array.from(queryParams).length > 0 && moduleOptions.length > 0 && truckOptions.length > 0 && palletOptions.length > 0) {
      let _formData = {};

      for (let [key, value] of queryParams.entries()) {
        if (key === "orderNumber" || key == "dispatchDate") {
          _formData[key] = value;
          continue;
        }

        let currentOptions = [];
        if (key === "moduleName") currentOptions = moduleOptions;
        if (key === "vehicleNo") currentOptions = truckOptions;
        if (key === "palletNo") currentOptions = palletOptions;

        const matchingOption = currentOptions.find(option => option.value.toLowerCase() === value.toLowerCase());
        if (matchingOption) {
          _formData[key] = matchingOption.value; // Use the case from the options to ensure consistency
        }
      }
      setFormData(prevFormData => ({
        ...prevFormData,
        ..._formData,
      }));

      handleSearch({ formData: _formData });
    }
  }, [location.search, moduleOptions, truckOptions, palletOptions]);*/

  useEffect(() => {
    if (images.length > 0) {
      setLoadingContext(true);
      const fetchPromises = images.map(imageId =>
        axiosInstanceOAuth.get(`/getdocumentbyfileid?fileId=${imageId}`)
      );

      Promise.allSettled(fetchPromises)
        .then(results => {
          const allImageData = results
            .filter(result => result.status === 'fulfilled')
            .map(result => result.value.data);

          setImageData(allImageData);

          const failedImages = results
            .filter(result => result.status === 'rejected')
            .map(result => result.reason);

          if (failedImages.length > 0) {
            console.error('Failed to load some images:', failedImages);
            showWarningError('Failed to load some images');
          }
        })
        .catch(error => {
          console.error('Error fetching images:', error);
          showNotificationError('Error fetching images:', error);
        }).finally(() => setLoadingContext(false));
    }
  }, [images]);

  const handleChange = (name, selectedOption) => {
    let objToUpdate = {};
    if (selectedOption === null || selectedOption === undefined)
      objToUpdate = { [name]: null };
    else if (name == "dispatchDate")
      objToUpdate = { [name]: selectedOption };
    else
      objToUpdate = { [name]: selectedOption.value };

    setFormData(prevState => ({
      ...prevState,
      ...objToUpdate
    }));
  };
  const handleModuleChange = (selectedOption) => {
    handleChange('moduleName', selectedOption);
  };
  const handlePrincipleChange = (selectedOption) => {
    handleChange('principleCode', selectedOption);
  };
  const handleTruckChange = (selectedOption) => {
    handleChange('vehicleNo', selectedOption);
  };
  const handlePalletChange = (selectedOption) => {
    handleChange('palletNo', selectedOption);
  };
  const handleDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0].replace(/-/g, '/');
    handleChange('dispatchDate', formattedDate);
  };
  const loadOptions = async (inputValue) => {
    if (inputValue.length > 5) {
      try {
        const response = await httpGacwareInstanceAuth.get(`/document/jobs?jobno=${inputValue}&principleCode=${formData.principleCode}`);
        return response.data.map((item) => ({ value: item, label: item }));
      } catch (error) {
        showNotificationError('Error loading jobs', error);
        return [];
      }
    }
  };
  const handleOrderNumberChange = (selectedOption) => {
    handleChange('orderNumber', selectedOption);
  };
  const handleSearch = ({ formData: _formData = null }) => {

    // Re-initialize images and imageData
    setImages([]);
    setImageData([]);
    setGallerResetKey(prevKey => prevKey + 1);
    if (_formData == null)
      _formData = formData;
    let url = "/document/images";
    const queryParams = new URLSearchParams();
    let selectedKeysCount = 0;
    let mandatoryKeyPresent = false;

    Object.keys(_formData).forEach(key => {
      const value = _formData[key];
      if (value !== null && value !== '') {
        selectedKeysCount++;

        if (key === 'vehicleNo' || key === 'orderNumber' || key === 'palletNo' ) {
          mandatoryKeyPresent = true;
        }

        if (key === 'dispatchDate') {
          // Assuming you want to format the date as 'YYYY-MM-DD'
          queryParams.append(key, value);//toISOString().split('T')[0]
        } else {
          queryParams.append(key, value);
        }
      }
    });

    if (selectedKeysCount < 2 || !mandatoryKeyPresent) {
      showNotificationError('Please select at least two fields and ensure one of VehicleId, Pallet No, or Job Number is selected.');
      setLoadingContext(false);
      return;
    }

    const searchUrl = `${url}?${queryParams.toString()}`;
    setLoadingContext(true);

    httpGacwareInstanceAuth.get(`${searchUrl}`)
      .then((response) => {
        setImages(response.data.map(item => item.ImageId));
        if (response.data.length === 0) {
          showWarningAlert(['No images found for the selected criteria']);
        }
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
        showNotificationError('Error fetching images:', error);
      })
      .finally(() => setLoadingContext(false));
  };
  const resetForm = () => {
    setFormData(generateInitialFormData());
    setImages([]);
    setImageData([]);
    setGallerResetKey(prevKey => prevKey + 1);
    setResetKey(prevKey => prevKey + 1); // Increment key to force re-render
  };
  return (
    <div key={resetKey}>
      <Loader loading={loadingContext} />
      <ToastContainer />
      <PageHeader titles="GAC Scan Search" active="Image Download" items={['Gallery']} />
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Row className="align-items-end">
              <Col md={1} className="form-group">
                  <label htmlFor="principle-select">Principle Code</label>
                  <Select
                    isClearable
                    value={formData ? { value: formData.principleCode, label: formData.principleCode } : null}
                    isMulti={false}
                    id="principle-select"
                    options={principleOptions}
                    className="farm"
                    onChange={handlePrincipleChange}
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <label htmlFor="module-select">Module</label>
                  <Select
                    isClearable
                    value={formData ? { value: formData.moduleName, label: formData.moduleName } : null}
                    isMulti={false}
                    id="module-select"
                    options={moduleOptions}
                    className="farm"
                    onChange={handleModuleChange}
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col md={2} className="form-group">
                  <label htmlFor="truck-select">Truck / Vehicle ID</label>
                  <Select
                    isClearable
                    value={{ value: formData.vehicleNo, label: formData.vehicleNo }}
                    isMulti={false}
                    id="truck-select"
                    options={truckOptions}
                    className="farm"
                    onChange={handleTruckChange}
                    classNamePrefix="react-select"
                    isDisabled={!!formData.orderNumber} // Disable if orderNumber is present
                  />
                </Col>
                <Col md={1} className="form-group">
                  <label htmlFor="date-picker">Dispatch Date</label>
                  <DatePicker
                    className="form-control fc-datepicker"
                    format="YYYY/MM/DD"
                    value={formData.dispatchDate}
                    id="date-picker"
                    onChange={handleDateChange}
                    multiple={false}
                    numberOfMonths={1}
                    defaultValue={formData.dispatchDate}
                  />
                </Col>
                <Col md={2} className="form-group">
                  <label htmlFor="job-number-input">OBD / Job no</label>

                  <AsyncSelect
                    id="jobNumber"
                    isMulti={false}
                    className="farm"
                    value={{ value: formData.orderNumber, label: formData.orderNumber }}

                    loadOptions={loadOptions}
                    onChange={handleOrderNumberChange}
                    isClearable
                    isDisabled={!!formData.vehicleNo} // Disable if vehicleNo is present
                  />
                </Col>
                <Col md={2} className="form-group">
                  <label htmlFor="pallet-input">Pallet</label>

                  <Select
                    value={formData ? { value: formData.palletNo, label: formData.palletNo } : null}
                    isMulti={false}
                    id="pallet-select"
                    options={palletOptions}
                    className="farm"
                    onChange={handlePalletChange}
                    classNamePrefix="react-select"
                    isClearable
                  />
                </Col>
                <Col md={2} className="text-md-right form-group">
                  <button className="btn btn-primary mr-2" onClick={handleSearch} style={{ marginRight: "10px" }}><i className="fe fe-search"></i> Retrieve</button>
                  <button className="btn btn-secondary" onClick={resetForm}><i className="fe fe-x"></i> Reset</button>
                </Col>

              </Row>
            </Card.Body>

          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header className="card-header border-bottom-0">
              <h3 className="card-title">Results</h3>
            </Card.Header>
            <Card.Body>

              <div className="image-gallery mt-3" key={gallerResetKey}>
                <MyImageGallery images={imageData} />
              </div>
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </div>
  );
}
