const clientId = import.meta.env.VITE_AUTH_CLIENT_ID;
const authority = import.meta.env.VITE_AUTH_AUTHORITY;
const redirectUri = import.meta.env.VITE_AUTH_REDIRECTURI;

export const msalConfig = {
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: redirectUri 
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,  // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };