import React, { useEffect, useState, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import { Modal, Button } from "react-bootstrap";
import 'react-image-gallery/styles/css/image-gallery.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { axiosInstanceOAuth } from '../config/AuthService';
import '../assets/css/ImageGallery1.css';
import MultipleImgDownloadBtn from './MultipleImgDownloadBtnComponent';

const MyImageGallery = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageGalleryData, setImageGalleryData] = useState([]);
  const imageGalleryRef = useRef();
  const [selectedImages, setSelectedImages] = useState({});


  const onImageClick = () => {
    setCurrentImageIndex(imageGalleryRef.current.getCurrentIndex());
    setModalIsOpen(true);
  };

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImages({});
      let data = images.map(item => ({
        original: item.downloadUrl,
        thumbnail: item.downloadUrl,
        description: item.fileName,
        id: item.id,
        module :item.module
      }));
      setImageGalleryData(data);
    }
  }, [images]);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  /*useEffect(() => {
    const modalDialog = document.querySelector('.modal-dialog');
    if (modalDialog) {
      modalDialog.style.maxWidth = '80%'; // Set the width to 80% of the viewport width
      modalDialog.style.height = 'auto'; // Set the height to auto initially
      modalDialog.style.maxHeight = '90vh'; // Max height to 90% of the viewport height
      modalDialog.style.overflowY = 'auto'; // Enable scroll if content is taller than max height
    }
  }, [modalIsOpen]);
  */
  const downloadImage = async () => {
    const currentImage = imageGalleryData[currentImageIndex];
    if (currentImage) {
      try {
        const response = await axiosInstanceOAuth.get(`/download?documentId=${currentImage.id}&module=${currentImage.module}`, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', currentImage.description || 'downloaded-image.jpg'); // Provide a default filename
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove(); // Clean up
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    }
  };
  const selectImage = (image) => {
    let id = image.id;
    setSelectedImages((prevSelectedImages) => ({
      ...prevSelectedImages,
      [id]: !prevSelectedImages[id],
    }));
  };
  // Custom rendering of the gallery's slide
  const renderItem = (item) => {
    return (
      <div>

        <img className="image-gallery-image" src={item.original} alt={item.description} />


      </div>
    );
  };
  /*
          <button
            className='image-select-button'
            onClick={() => selectImage(item.id)}
            aria-pressed={selectedImages[item.id]}
          >
            {selectedImages[item.id] ? 'Deselect' : 'Select'}
          </button>
                    <div className='image-gallery-thumbnail-label'>{item.description}</div>
   <button 
            className='image-select-button' 
            onClick={() => selectImage(item.id)} 
            aria-pressed={selectedImages[item.id]}>
              {selectedImages[item.id] ? 'Deselect' : 'Select'}
          </button>
  */
  // Custom rendering for thumbnails
  const renderThumbInner = (item) => {
    const checkboxId = `checkbox-${item.id}`;
    return (
      <div className='image-gallery-thumbnail-container'>
        <img src={item.thumbnail} alt={item.description} />

        <div className="custom-checkbox custom-control">
          <input
            type="checkbox"
            className="custom-control-input"
            id={checkboxId}
            onChange={(e) => selectImage(item)}
            checked={selectedImages[item.id] ? true : false}
          />
          <label htmlFor={checkboxId} className="custom-control-label">

          </label>
        </div>
      </div>

    );
  };

  const handleSelectAll = () => {
    const isAnyImageNotSelected = images.some(item => !selectedImages[item.id]);

    if (isAnyImageNotSelected) {
      // If any image is not selected, select all
      const allSelected = {};
      images.forEach(item => {
        allSelected[item.id] = true;
      });
      setSelectedImages(allSelected);
    } else {
      // If all images are selected, deselect all
      setSelectedImages({});
    }
  };

  const areAllImagesSelected = () => {
    return images.every(item => selectedImages[item.id]);
  };


  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button variant="success" className="btn btn-primary btn-icon text-white me-3" onClick={handleSelectAll}> <i className="fe fe-check-square"></i>
          {areAllImagesSelected() ? " Deselect All" : " Select All"}</Button>
        <MultipleImgDownloadBtn selectedImageIds={selectedImages} allImages={imageGalleryData}/>
      </div>
      <ImageGallery
        ref={imageGalleryRef}
        showPlayButton={false}
        items={imageGalleryData}
        renderItem={renderItem}
        onClick={onImageClick}
        showBullets
        thumbnailPosition={"top"}
        //renderItem={() => null} // Render nothing for the main image
        renderThumbInner={renderThumbInner}
      //additionalClass='custom-image-gallery'
      />
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        size="xl"
        fullscreen
      //style={customModalStyle} // This applies the inline styles
      >
        <Modal.Header>
          <Modal.Title>Image</Modal.Title>
          <button onClick={closeModal} className="btn btn-icon me-3">
            <i className="fe fe-x"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <div className="tools">
                  <button style={{ marginRight: "10px" }} onClick={() => zoomIn()} className="btn btn-primary mr-2"><i style={{ fontSize: '24px' }} className="fe fe-zoom-in"></i> </button>
                  <button style={{ marginRight: "10px" }} onClick={() => zoomOut()} className="btn btn-primary mr-2"><i style={{ fontSize: '24px' }} className="fe fe-zoom-out"></i> </button>
                  <button onClick={() => resetTransform()} className="btn btn-secondary"><i style={{ fontSize: '24px' }} className="fe fe-x"></i></button>
                </div>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  contentStyle={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={imageGalleryData[currentImageIndex]?.original || ""}
                    alt={imageGalleryData[currentImageIndex]?.description || ""}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }} // objectFit contain will ensure the image is fully visible
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="success" onClick={downloadImage}>Download</Button>

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyImageGallery;